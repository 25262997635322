<template>
  <div>
    <app-main ref="main">
      <!-- 顶部广告 -->
      <template v-slot:main-top>
        <div @click="adClick" class="ad" ref="ad">
          <img src="../../../assets/images/mall/adddd.png" />
        </div>
      </template>
      <!-- 主要内容 -->
      <template v-slot:main-content>
        <!-- Step1 -->
        <div class="step">
          <p class="stepTitle">Step1：选择数据来源平台</p>
          <div class="step-body">
            <div
              @click="stepOneItemClick(index)"
              v-for="(item, index) in stepOne"
              :key="index"
              class="step-body-item"
              :class="{ active: stepOneActive == index }">
              {{ item }}
            </div>
          </div>
        </div>
        <!-- Step2 -->
        <div class="step">
          <p class="stepTitle">Step2：录入收件人信息</p>
          <div class="step-body">
            <div
              @click="stepTwoItemClick(item, index)"
              v-for="(item, index) in stepTwo"
              :key="index"
              class="step-body-item"
              :class="{ active: stepTwoActive == index }"
            >
              {{ item }}
            </div>
          </div>
        </div>
        <!-- 录入收件人信息 -->
        <!-- 模板导入 -->
        <el-collapse-transition>
          <div v-if="templateImport" class="selectWay templateImport">
            <div class="upload-left">
              <el-upload
                class="upload"
                drag
                action=""
                accept=".xls, .xlsx, .csv, .xlsm, .xltx"
                :show-file-list="false"
                :http-request="uploadFile"
                multiple
              >
                <i class="el-icon-upload"></i>
                <div class="el-upload__text">
                  将文件拖到此处，或 <em>点击上传</em><br /><span
                    class="upload-span"
                    >仅支持CSV、XLS、XLSM、XLTX、XLSX文件</span
                  >
                </div>
              </el-upload>
            </div>
            <div class="upload-right">
              <p>注意事项:</p>
              <p>1.从官方平台导出的订单模板，无需任何修改，直接上传即可</p>
              <p>
                2.可选择下载本平台指定模板格式上传，点击
                <a :href="url + '/uploads/地址模板.xlsx'">下载模板</a>
              </p>
              <p>3.仅支持CSV、XLS、XLSM、XLTX、XLSX文件</p>
              <p>
                4.为不影响您的正常发货，请留意各省市快递停发区域。
                <span @click="seeSending">点击查看停发区域</span>
              </p>
            </div>
          </div>
        </el-collapse-transition>
        <!-- 店铺导入 -->
        <el-collapse-transition>
          <div v-if="storeImport" class="selectWay">
            <div class="store-select">
              <span>选择店铺：</span>
              <el-select v-model="shopValue" placeholder="请选择店铺">
                <el-option
                  v-for="item in shopOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
              <span
                style="margin-left:16px;color:#1890FF;cursor:pointer"
                @click="jumpToStoreBind"
                >绑定店铺</span
              >
            </div>
            <div class="flag-select">
              <span>标记筛选：</span>
              <el-radio-group v-model="radio">
                <el-radio :label="6">全部</el-radio>
                <el-radio :label="1"
                  ><i
                    class="el-icon-s-flag"
                    style="color:red;font-size:18px"
                  ></i
                ></el-radio>
                <el-radio :label="2"
                  ><i
                    class="el-icon-s-flag"
                    style="color:#ebe534;font-size:18px"
                  ></i
                ></el-radio>
                <el-radio :label="3"
                  ><i
                    class="el-icon-s-flag"
                    style="color:#34eb4f;font-size:18px"
                  ></i
                ></el-radio>
                <el-radio :label="4"
                  ><i
                    class="el-icon-s-flag"
                    style="color:#3452eb;font-size:18px"
                  ></i
                ></el-radio>
                <el-radio :label="5"
                  ><i
                    class="el-icon-s-flag"
                    style="color:#b434eb;font-size:18px"
                  ></i
                ></el-radio>
              </el-radio-group>
            </div>
            <div class="flag-select" style="display: flex;">
              <span>订单编号:</span>
              <el-input type="textarea"  v-model="keywords" style="margin-left: 10px;width:800px" placeholder="多个订单号,回车键换行，不输入则筛选全部"/>
           </div>
            <div class="selectWay">
              <!-- 标题 -->
              <div class="list-title">
                <div>
                  <span class="span-one" style="cursor: pointer;" >点击获取订单以获取订单</span
                  ><span class="span-two"
                    >共上传<span class="span-three">{{
                      addressList.length
                    }}</span
                    >条数据</span
                  >
                </div>
                <div>
                  <el-button @click="getOrderListByStoreName"
                    >获取订单</el-button
                  ><el-button @click="nextStep" type="primary"
                    >信息正确,下一步</el-button
                  >
                </div>
              </div>
              <!-- 订单展示表格 -->
              <el-table
                :header-cell-style="{ background: '#fafafa' }"
                :data="
                  addressList.slice(
                    (currentPage - 1) * pageSize,
                    currentPage * pageSize
                  )
                "
                style="width: 100%"
              >
                <el-table-column label="序号" min-width="5%">
                  <template slot-scope="scope"
                    ><span>{{
                      scope.$index + (currentPage - 1) * pageSize + 1
                    }}</span></template
                  >
                </el-table-column>
                <el-table-column prop="order" label="订单编号" min-width="10%">
                </el-table-column>
                <el-table-column prop="nick" label="旺旺名" min-width="10%">
                </el-table-column>
                <el-table-column prop="title" label="商品名称" min-width="16%">
                </el-table-column>
                <el-table-column prop="name" label="收货人姓名" min-width="10%">
                </el-table-column>
                <el-table-column prop="phone" label="手机号" min-width="10%">
                </el-table-column>
                <el-table-column
                  prop="address"
                  label="收货地址"
                  min-width="30%"
                >
                </el-table-column>
                <el-table-column label="操作" min-width="10%">
                  <template slot-scope="scope">
                    <el-button
                      size="mini"
                      @click="
                        handleOrderListEdit(
                          scope.$index + (currentPage - 1) * pageSize + 1,
                          scope.row
                        )
                      "
                      >编辑</el-button
                    >
                    <el-button
                      size="mini"
                      type="danger"
                      @click="
                        handleOrderListDelete(
                          scope.$index + (currentPage - 1) * pageSize + 1,
                          scope.row
                        )
                      "
                      >删除</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
              <el-pagination
                @current-change="handleCurrentChange"
                background
                class="addressListPage"
                layout="prev, pager, next"
                :current-page="currentPage"
                :page-size="pageSize"
                :total="addressList.length"
              >
              </el-pagination>
            </div>
            <!--编辑表格dialog-->
            <el-dialog
              title="编辑收货人信息"
              width="30%"
              :visible.sync="dialogFormVisible2"
            >
              <el-form class="editForm" :model="editObj">
                <el-form-item label="收货人姓名">
                  <el-input v-model="editObj.name"></el-input>
                </el-form-item>
                <el-form-item label="手机号码">
                  <el-input v-model="editObj.phone"></el-input>
                </el-form-item>
                <el-form-item label="收货地址">
                  <el-input
                    v-model="editObj.address"
                    type="textarea"
                  ></el-input>
                </el-form-item>
              </el-form>
              <div slot="footer" class="dialog-footer">
                <el-button size="small" @click="dialogFormVisible2 = false"
                  >取 消</el-button
                >
                <el-button size="small" type="primary" @click="dialogFormYes"
                  >确 定</el-button
                >
              </div>
            </el-dialog>
          </div>
        </el-collapse-transition>
        <!-- 手工填写 -->
        <el-collapse-transition>
          <div v-if="handWritten" class="selectWay">
            <el-tabs @tab-click="handleClick" value="first">
              <!-- 地址格式一 -->
              <el-tab-pane label="地址格式一" name="first">
                <div class="addressOne">
                  <div class="addressOne-l">
                    <el-input
                      type="textarea"
                      class="textarea"
                      :autosize="{ minRows: 6 }"
                      v-model="textareaAddressValue"
                      placeholder="姓名，电话，地址（中间用逗号分隔，回车键换行，一行一条~）
  姓名，电话，地址
  姓名，电话，地址
  姓名，电话，地址"
                    >
                    </el-input>
                    <el-button
                      type="primary"
                      size="medium"
                      @click="addAddressOne"
                      >确认添加</el-button
                    >
                  </div>
                  <div class="addressOne-r">
                    <p>注意事项:</p>
                    <p>
                      1.姓名，电话，地址
                      按顺序填写，中间用逗号分隔，一次可以输入多个收件人信息，一个收件人信息一行输入。
                    </p>
                    <p>
                      2.如遇东莞市、三沙市、儋州市、嘉峪关市等没有区的城市，区域可设置为“其他区”
                    </p>
                    <p>
                      3.为不影响您的正常发货，请留意各省市快递停发区域。
                      <span @click="seeSending">点击查看停发区域</span>
                    </p>
                    <br />
                    <p>示例:</p>
                    <p>张三，13888888888，湖北省 武汉市 江汉区 解放大道392号</p>
                  </div>
                </div>
              </el-tab-pane>
              <!-- 地址格式二 -->
              <el-tab-pane label="地址格式二" name="second">
                <div class="addressTwo">
                  <div class="addressTwo-l">
                    <el-form :inline="true" :model="arsObj">
                      <el-form-item>
                        <el-input
                          v-model="arsObj.order"
                          type="text"
                          placeholder="订单编号(可不填)"
                          style="width: 150px;"
                        ></el-input>
                      </el-form-item>
                      <el-form-item>
                        <el-input
                          v-model="arsObj.name"
                          type="text"
                          placeholder="姓名"
                          style="width: 100px;"
                        ></el-input>
                      </el-form-item>
                      <el-form-item>
                        <el-input
                          v-model="arsObj.phone"
                          type="text"
                          placeholder="手机号"
                          style="width: 150px;"
                        ></el-input>
                      </el-form-item>
                      <el-form-item>
                        <el-input
                          v-model="arsObj.province"
                          type="text"
                          placeholder="输入省"
                          style="width: 100px;"
                        ></el-input>
                      </el-form-item>
                      <el-form-item>
                        <el-input
                          v-model="arsObj.city"
                          type="text"
                          placeholder="输入市"
                          style="width: 100px;"
                        ></el-input>
                      </el-form-item>
                      <el-form-item>
                        <el-input
                          v-model="arsObj.area"
                          type="text"
                          placeholder="输入县/区"
                          style="width: 100px;"
                        ></el-input>
                      </el-form-item>
                      <el-form-item>
                        <el-input
                          v-model="arsObj.detail"
                          type="text"
                          placeholder="详细地址，街道，楼栋等"
                          style="width: 280px;"
                        ></el-input>
                      </el-form-item>
                    </el-form>
                    <el-button
                      @click="addAddressTwo"
                      type="primary"
                      size="medium"
                      >确认添加</el-button
                    >
                  </div>
                  <div class="addressTwo-r">
                    <p>注意事项:</p>
                    <p>
                      1.姓名，电话，地址
                      按顺序填写，中间用逗号分隔，一次可以输入多个收件人信息，一个收件人信息一行输入。
                    </p>
                    <p>
                      2.如遇东莞市、三沙市、儋州市、嘉峪关市等没有区的城市，区域可设置为“其他区”
                    </p>
                    <p>
                      3.为不影响您的正常发货，请留意各省市快递停发区域。
                      <span @click="seeSending">点击查看停发区域</span>
                    </p>
                    <br />
                    <p>示例:</p>
                    <p>张三，13888888888，湖北省 武汉市 江汉区 解放大道392号</p>
                  </div>
                </div>
              </el-tab-pane>
            </el-tabs>
          </div>
        </el-collapse-transition>
        <!-- 收货人信息列表 -->
        <div v-if="listTableFlag" class="selectWay">
          <!-- 标题 -->
          <div class="list-title">
            <div>
              <span class="span-one">根据导入内容，核对收件人信息</span
              ><span class="span-two"
                >共上传<span class="span-three">{{ addressList.length }}</span
                >条数据</span
              >
            </div>
            <div>
              <el-button @click="uploadAgain">导入订单</el-button
              ><el-button @click="nextStep" type="primary"
                >信息正确,下一步</el-button
              >
            </div>
          </div>
          <!-- 表格 -->
          <el-table
            :header-cell-style="{ background: '#fafafa' }"
            :data="
              addressList.slice(
                (currentPage - 1) * pageSize,
                currentPage * pageSize
              )
            "
            style="width: 100%"
          >
            <el-table-column label="序号" min-width="10%">
              <template slot-scope="scope"
                ><span>{{
                  scope.$index + (currentPage - 1) * pageSize + 1
                }}</span></template
              >
            </el-table-column>
            <el-table-column label="订单编号" min-width="16%">
              <template slot-scope="scope"
                ><span>{{ scope.row.order }}</span></template
              >
            </el-table-column>
            <el-table-column prop="name" label="收货人姓名" min-width="16%">
            </el-table-column>
            <el-table-column prop="phone" label="手机号" min-width="16%">
            </el-table-column>
            <el-table-column prop="address" label="收货地址" min-width="30%">
            </el-table-column>
            <el-table-column label="操作" min-width="10%">
              <template slot-scope="scope">
                <el-button
                  size="mini"
                  @click="
                    handleEdit(
                      scope.$index + (currentPage - 1) * pageSize + 1,
                      scope.row
                    )
                  "
                  >编辑</el-button
                >
                <el-button
                  size="mini"
                  type="danger"
                  @click="
                    handleDelete(
                      scope.$index + (currentPage - 1) * pageSize + 1,
                      scope.row
                    )
                  "
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            @current-change="handleCurrentChange"
            background
            class="addressListPage"
            layout="prev, pager, next"
            :current-page="currentPage"
            :page-size="pageSize"
            :total="addressList.length"
          >
          </el-pagination>
        </div>
        <!-- 编辑表格Dialog -->
        <el-dialog
          title="编辑收货人信息"
          width="30%"
          :visible.sync="dialogFormVisible"
        >
          <el-form class="editForm" :model="editObj">
            <el-form-item label="订单编号">
              <el-input v-model="editObj.order"></el-input>
            </el-form-item>
            <el-form-item label="收货人姓名">
              <el-input v-model="editObj.name"></el-input>
            </el-form-item>
            <el-form-item label="手机号码">
              <el-input v-model="editObj.phone"></el-input>
            </el-form-item>
            <el-form-item label="收货地址">
              <el-input v-model="editObj.address" type="textarea"></el-input>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button size="small" @click="dialogFormVisible = false"
              >取 消</el-button
            >
            <el-button size="small" type="primary" @click="dialogFormYes"
              >确 定</el-button
            >
          </div>
        </el-dialog>
        <!-- Step3 -->
        <div class="step three" ref="stepThree">
          <p class="stepTitle">Step3：选择礼品</p>
          <div class="step-body step-three">
            <!-- 左边 -->
            <div class="step-body-left">
              <!-- 仓库 -->
              <div class="screening">
                <div class="title">仓库:</div>
                <ul class="list">
                  <li
                    @click="storeItemClick(index)"
                    :class="{ 'screening-active': storeActive == index }"
                    class="item"
                    v-for="(item, index) in storeList"
                    :key="index"
                  >
                    {{ item.name }}
                  </li>
                </ul>
              </div>
              <!-- 排序 -->
              <div class="screening">
                <div class="title">排序:</div>
                <ul class="list">
                  <li
                    @click="sortItemClick(index)"
                    :class="{ 'screening-active': sortActive == index }"
                    class="item"
                    v-for="(item, index) in sortList"
                    :key="index"
                  >
                    {{ item }}
                  </li>
                </ul>
              </div>
              <!-- 礼品列表 -->
              <div class="goods">
                <div
                  class="goods-item"
                  v-for="(item, index) in goodsList.slice(
                    (goodsCurrentPage - 1) * goodsPageSize,
                    goodsCurrentPage * goodsPageSize
                  )"
                  :key="index"
                >
                  <div class="image">
                    <img :src="item.goods_images[0]" />
                  </div>
                  <div class="btn" @click="addGoods(item)">确认礼品</div>
                  <div class="price">￥{{ item.goods_price }}</div>
                  <div class="message">
                    <span>{{ item.goods_name }}</span>
                    <span>{{ item.goods_weight }}g</span>
                  </div>
                </div>
              </div>
              <!-- 分页 -->
              <el-pagination
                background
                @current-change="goodsHandleCurrentChange"
                :current-page="goodsCurrentPage"
                :page-size="goodsPageSize"
                layout="total, prev, pager, next, jumper"
                :total="goodsList.length"
              >
              </el-pagination>
            </div>
            <!-- 右边 -->
            <div class="step-body-right">
              <div class="table-box">
                <!-- 表格 -->
                <table>
                  <thead class="table-thead">
                    <tr>
                      <th>礼品图</th>
                      <th>礼品名称</th>
                      <th>单价</th>
                      <th>数量</th>
                      <th>重量（g）</th>
                      <th>发货仓库</th>
                      <th>小计</th>
                    </tr>
                  </thead>
                  <tbody v-show="dataFlag" class="table-tbody"></tbody>
                </table>
                <!-- 没有数据时显示 -->
                <div v-show="!dataFlag" class="no-data">
                  <div class="no-data-box">
                    <i
                      class="el-icon-takeaway-box icon"
                      style="font-size: 45px;"
                    ></i>
                    <p>暂无数据</p>
                  </div>
                </div>
              </div>
              <!-- 提交订单 -->
              <div class="sub-order">
                <el-form :model="goodsItem">
                  <el-form-item label="备注信息:">
                    <el-input v-model="remarks"></el-input>
                  </el-form-item>
                  <el-form-item label="运送方式:">
                    <el-select
                      v-model="storageValue"
                      placeholder="请选择快递"
                      @change="storageSelectChange"
                    >
                      <el-option
                        v-for="item in storageOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      ></el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item label="发件地址:">
                    <el-select
                      v-model="areaListValue"
                      placeholder="请选择发件人"
                      @change="areaSelectChange"
                    >
                      <el-option
                        v-for="item in areaList"
                        :key="item.id"
                        :label="item.name + ' ' + item.send_phone"
                        :value="item.id"
                      ></el-option>
                    </el-select>
                    <span class="xjdz" @click="mesBox">新建地址</span>
                  </el-form-item>
                  <el-form-item label="收件人数:">
                    <span>{{ addressList.length }}</span>
                  </el-form-item>
                  <el-form-item label="礼品总额:">
                    <span class="sub-order-color">￥{{ totalGifts }}</span>
                  </el-form-item>
                  <el-form-item label="礼品运费:">
                    <span>￥{{ freight }}</span>
                  </el-form-item>
                  <el-form-item label="应付金额:">
                    <span class="sub-order-color">￥{{ payable }}</span>
                  </el-form-item>
                  <span class="sub-btn" @click="placeOrder"
                    >提交订单 ￥{{ payable }}</span
                  >
                </el-form>
              </div>
            </div>
          </div>
        </div>
        <!-- 停发区域Dialog -->
        <stop-sending
          :showStopSending="showStopSending"
          @closeStopSending="closeStopSending"
          :stopStoreList="stopStoreList"
        />
        <!-- 付款Dialog -->
        <pay-dialog
          :dialogShow="dialogShow"
          @closePayDialog="closePayDialog"
          :name="goodsItem.goods_name"
          :price="payable"
          :money="money"
          :orderId="orderId"
        />
      </template>
    </app-main>
  </div>
</template>

<script>
import AddressParse from 'zh-address-parse' // 地址解析插件
import AppMain from 'components/baseStructure/AppMain'
import PayDialog from 'components/payDialog/PayDialog'
import StopSending from 'components/stopSending/StopSending'
import {
  getAllStorage,
  choosePlatform,
  getAllGoods,
  goodsSearchGs,
  createOrder,
  getAreaList,
  getUserMoney,
  importExcel,
  getStoreName,
  getTaoBaoOrderList,
  getpddOrderList
} from 'network/api'
// import { mustTwoDecimal } from 'utils/utils'

export default {
  components: {
    AppMain,
    PayDialog,
    StopSending
  },
  inject: ['mainReload'],
  data () {
    return {
      stepOne: ['淘宝/天猫', '阿里巴巴', '京东', '拼多多', '抖音', '其他'],
      stepTwo: ['模板导入', '店铺导入', '手工填写'],
      stepOneActive: null,
      stepTwoActive: null,
      templateImport: false, // 控制模板导入相关显示
      storeImport: false, // 控制店铺导入相关显示
      handWritten: false, // 控制手工填写相关显示
      /* 以下为地址数据 */
      textareaAddressValue: '',
      addressList: [],
      addressListTotal: null,
      currentPage: 1, // 当前页
      pageSize: 10, // 每页多少条
      listTableFlag: false, // 控制地址列表是否显示
      arsObj: {},
      dialogFormVisible: false, // 控制编辑Dialog显示
      dialogFormVisible2: false, // 控制店铺导入订单Dialog显示
      editObj: {
        order: undefined,
        name: undefined,
        phone: undefined,
        address: undefined
      }, // 编辑地址对象
      addressListItemIndex: null, // 选中地址的索引
      /* 以上为地址数据 */
      /* 以下为礼品数据 */
      storeList: [], // 仓库列表
      stopStoreList: [], // 停发仓库列表
      storeListItem: {}, // 仓库对象
      storeListItemName: '', // 仓库名字 添加礼品列表需要用到
      storeListItemId: null, // 仓库id
      sortList: ['默认', '价格从低到高', '销量从低到高', '重量从轻到重'],
      storeActive: 0,
      sortActive: 0,
      goodsPageSize: 20,
      goodsCurrentPage: 1,
      goodsList: [], // 礼品列表
      goodsItem: {}, // 礼品详情
      dataFlag: false, // true代表选择了礼品, false没有选择礼品
      storageValue: '', // 运送方式select绑定的数据
      storageOptions: [], // 运送方式option绑定的数据
      fPrice: '', // 运费
      /* 以上为礼品数据 */
      dialogShow: false,
      showStopSending: false,
      remarks: '', // 备注
      areaListValue: '',
      areaList: [], // 发件人列表
      area_id: '', // 地址id
      platform: '', // 平台
      platform2: '',
      money: '', // 账户余额
      orderId: '', // 订单id
      goodsType: '', // 商品排序标识
      shopValue: '', // 选择的店铺value
      shopOptions: [],
      radio: 6, // 店铺导入的flag
      orderList: [], // 店铺导入获取的订单列表
      newList: [],
      newList1: [],
      url: '',
      keywords: ''// 订单编号文本搜索
    }
  },
  computed: {
    // 计算礼品总额
    totalGifts () {
      console.log(
        '计算礼品总额',
        this.goodsItem.goods_price,
        this.addressList.length
      )
      if (this.addressList.length !== 0) {
        const n =
          parseFloat(
            this.goodsItem.goods_price === undefined ||
              !this.goodsItem.goods_price
              ? 0.0
              : this.goodsItem.goods_price
          ) * parseFloat(this.addressList.length)
        return n.toFixed(2)
      } else {
        const m = parseFloat(
          this.goodsItem.goods_price === undefined ||
            !this.goodsItem.goods_price
            ? 0.0
            : this.goodsItem.goods_price
        )
        return m.toFixed(2) || 0.0
      }
    },
    // 计算运费
    freight () {
      if (this.addressList.length !== 0) {
        const f =
          parseFloat(this.fPrice || 0.0) * parseFloat(this.addressList.length)
        return f.toFixed(2)
      } else {
        const y = parseFloat(this.fPrice) || 0.0
        return y.toFixed(2)
      }
    },
    // 计算应付金额
    payable () {
      const t = parseFloat(this.totalGifts)
      const e = parseFloat(this.freight === undefined ? 0.0 : this.freight)
      console.log('计算应付金额', this.totalGifts, this.freight)
      return (t + e).toFixed(2)
    }
  },
  created () {
    // 获取所有仓库信息
    getAllStorage({ appkey: this.$appkey }).then(res => {
      // console.log(res)
      this.storeList = res.data
      // 向数组中添加一个'全部'
      const i = { name: '全部' }
      if (this.storeList) {
        this.storeList.unshift(i)
      }
    })
    // 获取所有商品
    this.allGoods()
    this.url = this.$url
  },
  methods: {
    // 广告点击
    adClick () {
      window.open(this.$store.getters.getSiteInfo.online_service, '_blank')
    },
    // 选择数据来源平台点击
    stepOneItemClick (index) {
      this.stepOneActive = index
      this.shopValue = ''
      this.stepTwoActive = null
      this.templateImport = false
      this.storeImport = false
      this.handWritten = false
      this.listTableFlag = false
      switch (index) {
        case 0:
          this.platform = 'tb_switch'
          this.platform2 = '0'
          this.stepTwo = ['模板导入', '店铺导入', '手工填写']
          break
        case 1:
          this.platform = 'ali_switch'
          this.platform2 = '1'
          this.stepTwo = ['模板导入', '手工填写']
          break
        case 2:
          this.platform = 'jd_switch'
          this.platform2 = '2'
          this.stepTwo = ['模板导入', '手工填写']
          break
        case 3:
          this.platform = 'pinxixi_switch'
          this.platform2 = '3'
          this.stepTwo = ['模板导入', '店铺导入', '手工填写']
          break
        case 4:
          this.platform = 'dy_switch'
          this.platform2 = '4'
          this.stepTwo = ['店铺导入', '手工填写']
          break
        case 5:
          this.platform = 'others_switch'
          this.platform2 = '5'
          this.stepTwo = ['模板导入', '手工填写']
          break
      }
      // 获取点击平台的店铺名称
      getStoreName({
        bandType: this.platform2,
        appkey: this.$appkey
      }).then(res => {
        console.log(res)
        this.shopOptions = []
        if (res.data) {
          for (let i = 0; i < res.data.length; i++) {
            this.shopOptions.push({
              label: res.data[i][0],
              value: res.data[i][1] // 此处value未定 等待后续确定
            })
          }
        }
      })
      // 点击平台不同获取仓库列表不同
      choosePlatform({ platform: this.platform, appkey: this.$appkey }).then(res => {
        this.storeActive = 0
        this.storeList = res.data
        this.stopStoreList = res.data
        this.storeListItem = this.storeList[0] // 初始化仓库对象
        this.storeListItemId = this.storeList[0].id
        this.storeListItemName = this.storeList[0].name // 获取到仓库名
        // 初始化礼品列表
        this.getGoodsSearchGs()
      })
    },
    // 获取所有商品请求
    allGoods () {
      // getAllGoods().then(res => {
      //   this.goodsList = res.data
      // })
      getAllGoods({ appkey: this.$appkey }).then(res => {
        this.goodsList = res.data
      })
    },
    // 商品筛选请求
    getGoodsSearchGs () {
      goodsSearchGs({
        storage_id: this.storeListItemId,
        goods_type: this.goodsType,
        appkey: this.$appkey
      }).then(res => {
        // 筛选倒序数组
        switch (this.goodsType) {
          // 价格从低到高
          case 'goods_price':
            this.goodsList = res.data.reverse()
            break
          // 销量从低到高
          case 'goods_num':
            this.goodsList = res.data.reverse()
            break
          // 重量从轻到重
          case 'goods_weight':
            this.goodsList = res.data.reverse()
            break
          // 默认
          default:
            this.goodsList = res.data
            break
        }
      })
    },
    // 录入收件人信息的方式点击
    stepTwoItemClick (item, index) {
      // 需要先选中平台
      if (this.stepOneActive != null) {
        this.stepTwoActive = index
        switch (item) {
          case '模板导入':
            this.templateImport = true
            this.storeImport = false
            this.handWritten = false
            this.listTableFlag = false
            this.textareaAddressValue = ''
            this.addressList = []
            break
          case '店铺导入':
            this.templateImport = false
            this.storeImport = true
            this.handWritten = false
            this.listTableFlag = false
            this.textareaAddressValue = ''
            break
          case '手工填写':
            this.templateImport = false
            this.storeImport = false
            this.handWritten = true
            this.listTableFlag = false
            this.textareaAddressValue = ''
            break
        }
      } else {
        this.$message({
          message: '请先选择平台',
          type: 'warning'
        })
      }
    },
    jumpToStoreBind () {
      this.$router.push('storeBind')
    },
    // 地址格式一
    addAddressOne () {
      const v = this.textareaAddressValue // textarea 输入内容
      // 判断输入内容是否为空
      if (v === '') {
        this.$message({
          message: '请输入地址',
          type: 'warning'
        })
        return false
      }
      // 将输入内容转为数组
      let infoArray = [] // textarea 数组
      infoArray = v.split('\n')
      for (let i = 0; i < infoArray.length; i++) {
        if (infoArray[i] === '') {
          infoArray.splice(i, 1) // 去掉值为空的数组
          i = i - 1
        }
      }
      this.addressList = infoArray
      for (let i = 0; i < infoArray.length; i++) {
        const a = AddressParse(infoArray[i])
        // 判断格式是否正确
        if (
          a.name === '' ||
          a.phone === '' ||
          a.province === '' ||
          a.city === '' ||
          a.detail === ''
        ) {
          this.$message({
            message: '地址格式错误',
            type: 'warning'
          })
          return false
        } else {
          this.listTableFlag = true // 显示地址列表
          this.arsObj = a
          this.arsObj.order = this.randomNo(3) // 添加一个随机订单号
          this.arsObj.address = a.province + a.city + a.area + a.detail
          this.addressList[i] = this.arsObj
        }
      }
    },
    // 地址格式二
    addAddressTwo () {
      const v =
        this.arsObj.name +
        this.arsObj.phone +
        this.arsObj.province +
        this.arsObj.city +
        this.arsObj.area +
        this.arsObj.detail
      const a = AddressParse(v)
      if (
        !this.arsObj.name ||
        !this.arsObj.phone ||
        !this.arsObj.province ||
        !this.arsObj.city ||
        !this.arsObj.area ||
        !this.arsObj.detail
      ) {
        this.$message({
          message: '请完善信息',
          type: 'warning'
        })
      } else if (
        a.name === '' ||
        a.phone === '' ||
        a.province === '' ||
        a.city === '' ||
        a.area === '' ||
        a.detail === ''
      ) {
        this.$message({
          message: '地址格式错误',
          type: 'warning'
        })
      } else {
        this.listTableFlag = true // 显示地址列表
        this.arsObj.order = this.arsObj.order
          ? this.arsObj.order
          : this.randomNo(3)
        this.arsObj.address =
          this.arsObj.province +
          this.arsObj.city +
          this.arsObj.area +
          this.arsObj.detail
        this.addressList.push(this.arsObj)
        this.arsObj = {}
      }
    },
    getOrderListByStoreName () {
      if (this.radio === 6) {
        const a = null
        this.getding(a)
        this.addressList = this.newList
      } else {
        this.getding(this.radio)
        this.addressList = this.newList
      }
    },
    getding (zhi) {
      this.newList = []
      if (!this.shopValue) {
        this.$message({
          message: '请先选择店铺',
          type: 'warning'
        })
      } else {
        if (this.platform2 === '0') {
          getTaoBaoOrderList({
            tbseller: this.shopValue,
            seller_flag: zhi,
            keywords: this.keywords,
            appkey: this.$appkey
          }).then(res => {
            console.log(res)
            if (res.data) {
              for (let i = 0; i < res.data.length; i++) {
                // res.data[i].receive.receiver_address = res.data[i].receive.receiver_state + res.data[i].receive.receiver_city + res.data[i].receive.receiver_district + res.data[i].receive.receiver_address
                this.newList.push({
                  name: res.data[i].receive.receiver_name,
                  address:
                    res.data[i].receive.receiver_state +
                    res.data[i].receive.receiver_city +
                    res.data[i].receive.receiver_district +
                    res.data[i].receive.receiver_address,
                  province: res.data[i].receive.receiver_state,
                  city: res.data[i].receive.receiver_city,
                  area: res.data[i].receive.receiver_district,
                  phone: res.data[i].receive.receiver_mobile,
                  detail: res.data[i].receive.receiver_address,
                  order: res.data[i].receive.tid,
                  nick: res.data[i].receive.buyer_nick,
                  title: res.data[i].oid.title,
                  seller_flag: res.data[i].receive.seller_flag,
                  seller_nick: res.data[i].receive.seller_nick ? res.data[i].receive.seller_nick : ''
                })
              }
              console.log('触发订单处理s')
              console.log(this.newList)
            }
            if (res.code !== 1) {
              this.$message({
                message: res.msg,
                type: 'warning'
              })
            }
          })
        } else if (this.platform2 === '3') {
          getpddOrderList({
            pddSeller: this.shopValue,
            seller_flag: zhi,
            keywords: this.keywords,
            appkey: this.$appkey
          }).then(res => {
            console.log(res)
            if (res.data) {
              for (let i = 0; i < res.data.length; i++) {
                // res.data[i].receive.receiver_address = res.data[i].receive.receiver_state + res.data[i].receive.receiver_city + res.data[i].receive.receiver_district + res.data[i].receive.receiver_address
                this.newList.push({
                  name: res.data[i].receive.receiver_name,
                  address:
                    res.data[i].receive.receiver_state +
                    res.data[i].receive.receiver_city +
                    res.data[i].receive.receiver_district +
                    res.data[i].receive.receiver_address,
                  province: res.data[i].receive.receiver_state,
                  city: res.data[i].receive.receiver_city,
                  area: res.data[i].receive.receiver_district,
                  phone: res.data[i].receive.receiver_mobile,
                  detail: res.data[i].receive.receiver_address,
                  order: res.data[i].receive.tid,
                  nick: res.data[i].buyer_nick,
                  title: res.data[i].oid.title,
                  seller_flag: res.data[i].receive.seller_flag,
                  seller_nick: res.data[i].receive.seller_nick ? res.data[i].receive.seller_nick : ''
                })
              }
            }
            if (res.code !== 1) {
              this.$message({
                message: res.msg,
                type: 'warning'
              })
            }
          })
        }
      }
    },
    /* 以下为地址列表的方法 */
    // 重新上传
    uploadAgain () {
      this.listTableFlag = false
      this.addressList = []
      this.arsObj = {}
      this.editObj = {}
      switch (this.stepTwoActive) {
        case 0:
          this.templateImport = true
          break
        case 1:
          break
        case 2:
          this.textareaAddressValue = ''
          break
      }
    },
    // 信息正确,下一步
    nextStep () {
      const a = this.$refs.ad.offsetHeight // 顶部广告位的高度
      const o = this.$refs.stepThree.offsetTop // 距离父元素顶部的高度
      this.$refs.main.$parent.$parent.$refs.main.scrollTop = o - a
    },
    // 店铺导入订单编辑
    handleOrderListEdit (index, row) {
      this.addressListItemIndex = index - 1
      this.dialogFormVisible2 = true
      // console.log(row)
      const _row = row
      row = Object.assign({}, _row)
      this.editObj = row
    },
    handleOrderListDelete (index) {
      this.addressList.splice(index - 1, 1)
    },
    // 编辑
    handleEdit (index, row) {
      this.addressListItemIndex = index - 1
      this.dialogFormVisible = true
      const _row = row
      this.editObj = Object.assign({}, _row)
    },
    // 删除
    handleDelete (index, row) {
      this.addressList.splice(index - 1, 1)
    },
    // 当前页
    handleCurrentChange (val) {
      this.currentPage = val
    },
    // Dialog 确定
    dialogFormYes () {
      this.dialogFormVisible = false
      this.dialogFormVisible2 = false
      // 修改原数组地址
      this.addressList.splice(this.addressListItemIndex, 1, this.editObj)
    },
    /* 以上为地址列表的方法 */
    /* 以下为礼品列表的方法 */
    // 仓库筛选点击
    storeItemClick (index) {
      if (this.stepOneActive === null) {
        this.$message({
          message: '请先选择平台',
          type: 'warning'
        })
      } else {
        this.storeActive = index
        this.storeListItem = this.storeList[index] // 仓库对象
        this.storeListItemName = this.storeList[index].name // 获取到仓库名
        this.storeListItemId = this.storeList[index].id // 获取到仓库ID
        // 根据仓库获取礼品列表
        this.getGoodsSearchGs()
      }
    },
    // 排序筛选点击
    sortItemClick (index) {
      this.sortActive = index
      switch (index) {
        case 0:
          this.goodsType = ''
          if (!this.storeListItemId) {
            this.allGoods()
          } else {
            this.getGoodsSearchGs()
          }
          break
        case 1:
          this.goodsType = 'goods_price'
          this.getGoodsSearchGs()
          break
        case 2:
          this.goodsType = 'goods_num'
          this.getGoodsSearchGs()
          break
        case 3:
          this.goodsType = 'goods_weight'
          this.getGoodsSearchGs()
          break
      }
    },
    // 添加礼品
    addGoods (item) {
      console.log(item)
      // 首先需要判断是否选择了平台
      if (this.stepOneActive != null) {
        // 添加前清空
        this.storageOptions = []
        // 选中的礼品详情
        this.goodsItem = item
        // 选择仓库<el-option>使用
        const v = {}
        v.value = this.storeListItem.id
        v.label =
          this.storeListItem.deliver_name +
          ' / ' +
          this.storeListItem.deliver_price +
          '元'
        v.freight_price = this.storeListItem.deliver_price
        this.storageOptions.push(v)
        this.storageValue = this.storageOptions[0].value // 初始化<el-select>选中
        this.fPrice = this.storageOptions[0].freight_price // 初始化运费
        this.dataFlag = true // 礼品列表显示标识
        /* 以下为创建一个行添加到表格 */
        // 获取tbody
        var tb = document.getElementsByClassName('table-tbody')[0]
        // 添加前清空子元素
        tb.innerHTML = ''
        // 创建行
        var tr = document.createElement('tr')
        // 第一列
        var imgTd = document.createElement('td')
        var img = document.createElement('img')
        img.src = item.goods_images[0]
        imgTd.appendChild(img)
        tr.appendChild(imgTd)
        // 第二列
        var nameTd = document.createElement('td')
        nameTd.innerHTML = item.goods_name
        tr.appendChild(nameTd)
        // 第三列
        var priceTd = document.createElement('td')
        priceTd.innerHTML = item.goods_price
        tr.appendChild(priceTd)
        // 第四列
        var numberTd = document.createElement('td')
        numberTd.innerHTML = 1
        tr.appendChild(numberTd)
        // 第五列
        var weightTd = document.createElement('td')
        weightTd.innerHTML = item.goods_weight
        tr.appendChild(weightTd)
        // 第六列
        var storeTd = document.createElement('td')
        storeTd.innerHTML = this.storeListItemName
        tr.appendChild(storeTd)
        // 第七列
        var subtotalTd = document.createElement('td')
        subtotalTd.innerHTML = item.goods_price
        tr.appendChild(subtotalTd)
        // 添加样式
        var tds = tr.children
        img.style.width = '48px'
        img.style.height = '48px'
        for (let i = 0; i < tds.length; i++) {
          tds[i].style.padding = '16px'
          tds[i].style.borderBottom = '1px solid #e8e8e8'
        }
        // 添加到tbody
        tb.appendChild(tr)
        /* 以上为创建一个行添加到表格 */
        /* 获取到发件人列表 */
        getAreaList({ appkey: this.appkey }).then(res => {
          this.areaList = res.data // 发件人地址列表
          for (let i = 0; i < this.areaList.length; i++) {
            if (this.areaList[i].status === '1') {
              this.areaListValue =
                this.areaList[i].name + ' ' + this.areaList[i].send_phone // 初始化默认发件人地址
              this.area_id = this.areaList[i].id // 初始化发件人地址id
              return
            }
          }
        })
      } else {
        this.$message({
          message: '请先选择平台',
          type: 'warning'
        })
      }
    },
    // 分页 当前页
    goodsHandleCurrentChange (val) {
      this.goodsCurrentPage = val
    },
    /* 以上为礼品列表的方法 */
    // 提交订单
    placeOrder () {
      // 判断编辑后的地址格式是否正确
      if (this.addressList.length !== 0) {
        for (let i = 0; i < this.addressList.length; i++) {
          if (
            this.addressList[i].name === '' ||
            this.addressList[i].phone === '' ||
            this.addressList[i].address === ''
          ) {
            this.$message.error('第' + (i + 1) + '条收件人数据不全')
            return false
          }
          if (!/^1[3|4|5|6|9|7|8][0-9]{9}$/.test(this.addressList[i].phone)) {
            this.$message.error('第' + (i + 1) + '条收件人手机号有误')
            return false
          }
        }
      }
      if (!this.dataFlag) {
        this.$message.error('请选择礼品')
      } else if (this.addressList.length === 0) {
        this.$message.error('请至少填写一个收货地址')
      } else if (this.areaList.length === 0 || this.areaListValue === '') {
        this.$message.error('请选择发货地址')
      } else {
        // 获取账户余额
        getUserMoney({ appkey: this.$appkey }).then(res => {
          this.money = res.data.usermoney
        })
        const a = this.addressList

        console.log(a)
        const postData = {}
        postData.real_list = []
        for (let i = 0; i < a.length; i++) {
          const item = {}
          item.third_order_no = a[i].order
          item.receive_name = a[i].name
          item.receive_phone = a[i].phone
          item.receive_province = a[i].province
          item.receive_city = a[i].city
          item.receive_town = a[i].area
          item.receive_address = a[i].detail
          item.seller_nick = a[i].seller_nick ? a[i].seller_nick : ''
          postData.real_list.push(item)
        }
        postData.goods_id = this.goodsItem.id
        postData.storage_id = this.storeListItemId
        postData.order_tips = this.remarks
        postData.area_id = this.area_id
        postData.platform = this.platform2
        postData.appkey = this.$appkey
        if (this.shopValue) {
          postData.order_type = this.shopValue
        }
        createOrder(postData).then(res => {
          if (res.code === 1) {
            this.orderId = res.data.order_id
            this.dialogShow = true
          } else {
            this.$message(res.msg)
          }
        })
      }
    },
    // 通过监听子组件发送过来的事件关闭Dialog
    closePayDialog () {
      this.mainReload() // 重置
    },
    // 停发区域Dialog关闭回调
    closeStopSending () {
      this.showStopSending = false
    },
    // 查看停发
    seeSending () {
      this.showStopSending = true
    },
    // 仓库选择框
    storageSelectChange (e) {
      this.fPrice = e.freight_price
    },
    // 发件地址选择框
    areaSelectChange (e) {
      this.area_id = e // 改变发件人地址id
    },
    // 手工填写 ---> 地址格式切换
    handleClick () {
      this.listTableFlag = false
      this.arsObj = {}
      this.addressList = []
    },
    // 随机生成订单编号
    randomNo (j) {
      var randomno = ''
      for (var i = 0; i < j; i++) {
        randomno += Math.floor(Math.random() * 10)
      }
      randomno = new Date().getTime() + randomno
      return randomno
    },
    // 新建地址点击
    mesBox () {
      this.$confirm('前往添加发件地址, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$router.push('/orderAdmin/sendAddress')
        })
        .catch(() => {})
    },
    /**
     *
     * 模板导入
     * 自定义上传
     *
     */
    uploadFile (file) {
      importExcel(file.file).then(res => {
        if (res.code === 1) {
          for (let i = 0; i < res.data.length; i++) {
            this.arsObj.name = res.data[i].receive_name
            this.arsObj.phone = res.data[i].receive_phone
            this.arsObj.province = res.data[i].receive_province
            this.arsObj.city = res.data[i].receive_city
            this.arsObj.area = res.data[i].receive_town
            this.arsObj.detail = res.data[i].receive_address
            this.arsObj.order = res.data[i].third_order_no
            this.arsObj.address =
              res.data[i].receive_province +
              res.data[i].receive_city +
              res.data[i].receive_town +
              res.data[i].receive_address
            this.addressList.push(this.arsObj)
            this.arsObj = {}
          }
          this.listTableFlag = true // 显示地址列表
          this.templateImport = false // 上传成功隐藏模板导入
          this.$message({
            message: res.msg,
            type: 'success'
          })
        } else {
          this.$message.error(res.msg)
        }
      })
    }
  }
}
</script>

<style scoped src="styles/views/orderAdmin/purchase.css"></style>
