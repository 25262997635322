<template>
  <div class="payDialog">
    <el-dialog title="付款" width="720px" :visible.sync="dialogVisible" @close="closePayDialog">
      <div v-if="showSuccess" class="content">
        <div class="name">
          商品名称：<span>{{ name }}</span>
        </div>
        <div class="price">
          支付金额：<span>￥{{ price }}</span>
        </div>
        <div>
          <div class="payway-btn">
            <span>支付方式：</span>
            <div class="way">
              <div class="payway active">
                <img class="yue" src="~assets/icons/yue.png" />
                <img class="dagou" src="~assets/icons/dagou.png" />
                <span>账户余额</span>
              </div>
            </div>
          </div>
          <div class="payway-info">
            <p>
              账户余额：￥<span>{{ money }}</span
              >元<span @click="toRecharge">充值</span>
            </p>
          </div>
          <div class="zhu">
            注：支付宝单笔支付暂时关闭，请充值到余额支付
          </div>
          <el-button type="danger" :disabled="disabled" class="payment" @click="payment">{{ paymentText }}</el-button>
        </div>
      </div>
      <!-- 付款成功显示 -->
      <div v-else class="content">付款成功</div>
    </el-dialog>
  </div>
</template>

<script>
import { orderPay, stationpayFor } from 'network/api'
import { Loading } from 'element-ui'
export default {
  data() {
    return {
      dialogVisible: false,
      pri: '',
      mon: '',
      id: '',
      showSuccess: true,
      paymentText: '立即支付',
      disabled: false,
      pageTitle: ''
    }
  },
  props: {
    dialogShow: { default: false },
    name: null,
    price: null,
    money: null,
    orderId: null,
    PayDialog: null,
    pagetitle: null,
    fromList: { type: Boolean, default: false }
  },
  methods: {
    closePayDialog() {
      this.$emit('closePayDialog')
    },
    // 前往重置
    toRecharge() {
      this.$router.push('/account/balance')
    },
    // 支付
    payment() {
      // 判断余额不足
      var p = Number.parseFloat(this.pri)
      var m = Number.parseFloat(this.mon)
      if (p > m || Number.parseFloat(this.price) > Number.parseFloat(this.money)) {
        // console.log(p, m)
        this.$message({
          message: '当前账户余额不足',
          type: 'warning'
        })
        return false
      }
      // 余额支付
      if (this.pageTitle === '网站管理') {
        stationpayFor({ appkey: this.$appkey }).then(res => {
          if (res.code === 1) {
            this.$message({
              message: '恭喜你，付款成功！',
              type: 'success'
            })
            this.disabled = false
            location.reload()
          }
        })
      } else {
        const loadingInstance = Loading.service({ text: '支付中...' })
        orderPay(this.id, this.$appkey).then(res => {
          loadingInstance.close()
          this.paymentText = '支付中...'
          this.disabled = true
          const that = this
          // 支付成功
          if (res.code === 1) {
            this.$message({
              message: '恭喜你，付款成功！',
              type: 'success'
            })
            this.paymentText = '立即支付'
            this.disabled = false
            // 从商城引用
            if (!this.fromList) {
              setTimeout(function() {
                that.$router.push('/orderAdmin/orderList')
              }, 1500)
            } else {
              // 从列表引用
              setTimeout(function() {
                that.dialogVisible = false
              }, 1500)
            }
          } else {
            // 支付失败
            this.dialogVisible = false
            this.$message.error(res.msg)
            this.paymentText = '立即支付'
            this.disabled = false
          }
        })
      }
    }
  },
  watch: {
    pagetitle(val) {
      this.pageTitle = val
    },
    dialogShow(val) {
      this.dialogVisible = val
    },
    price(val) {
      this.pri = val
    },
    money(val) {
      this.mon = val
    },
    orderId(val) {
      this.id = val
    }
  }
}
</script>

<style scoped src="styles/components/payDialog.css"></style>

<style>
.payDialog .el-dialog__header {
  border-bottom: 1px solid #e8e8e8;
}
.payDialog .el-button--danger {
  background-color: #f93244;
  border-color: #f93244;
}
</style>
